//slick
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import '~slick-carousel/slick/slick', '~slick-carousel/slick/slick-theme';

/* Slider */
.slick-loading .slick-list {
  background: #fff url(https://kitato.com/img/vendor/slick-carousel/slick/ajax-loader.gif?fb6f3c230cb846e25247dfaa1da94d8f) center center no-repeat;
}

/* スライダー */
.home-slick{
    max-height: 450px;
    .slick-prev{
        left: 10px;
        z-index:100;
        color: white;
    }
    .slick-next{
        right: 25px;
        z-index:100;
        color: white;
    }    
    .slick-img {
        float: left;
        max-height: 400px;
        min-height: 100px;
        width: 320px;
       // overflow: hidden;
       // text-align: center;
       // margin: 0 auto;
    }
    @media (max-width:767.98px) {
        .slick-img img {
            display: block;
            height: 300px;
            width: 300px;
        }
        .slick-img:not(:first-child){
            display: none;
        }
    }
    @media (min-width:576px){
        .slick-img img {
            display: block;
            height: 320px;
            width: 320px;
        }
        .slick-img:nth-child(4){
            display: none;
        }
        .slick-img:nth-child(5){
            display: none;
        }
    }    
}
.slick-initialized{
    /* 中央以外のスライド */
    .slick-img img {
      display: block; /*slick-initializedが付与されたら表示*/
      opacity: .3; // スライドを薄くする
      transform: scale(.8); // スライドのサイズを80%にする
      //transition: opacity .5s, transform .5s; // スライド透過率と拡大のアニメーション時間を0.5秒に設定
      transition: opacity .5s, transform 0s; // スライド透過率と拡大のアニメーション時間を0.5秒に設定
      height: auto;
      width: 100%;
    }
    /* 中央のスライド */
    .slick-center img {
      opacity: 1; // 中央のスライドは透過しない
      transform: scale(1); // 中央のスライドは小さくしない
      transition: opacity .5s, transform .5s; // スライド透過率と拡大のアニメーション時間を0.5秒に設定
    }
}
/*TOP画像*/
.top-img{
    width: 100%;
    height: auto;
}
/* キービジュアル */
.key-visual {
    position: relative;
    /* Yu Gothic Bold 89px 49.0 1.0 */
    /* Yu Gothic 50px 0.0 1.1 */
}
#catchphrase{
    /*display: flex;*/
    position: absolute;
    /* Yu Gothic Bold 89px 49.0 1.0 */
    font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    letter-spacing: 7px;
    color:white;
    margin-bottom: 0;
    text-shadow: 2px 2px 3px #414141;
}
.flowing {
	width: 0;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	animation: flowing-anim 1s forwards linear;
}
.flowing.txt02 {
	animation-delay: 1.5s;
}
.flowing.txt03 {
	animation: flowing-anim 2s forwards linear;
	animation-delay: 3.5s;
}
.flowing.txt04 {
	animation: flowing-anim 3s forwards linear;
	animation-delay: 6s;
}
@keyframes flowing-anim {
 0%{
     width: 0%;
   }
100%{
     width: 100%;
   }
}
#sing{
    display: flex;
    position: absolute;
    /* Yu Gothic 50px 0.0 1.1 */
    font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:white;
}
.zoom_button,.zoom_button1{
    background-color: white;
    color: rgb(61, 61, 61);
}
.zoom_button::before,.zoom_button1::before{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid white;
}
.zoom_button span,.zoom_button1 span{
    position: relative;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.contact_button  {
    background-color: #0087d1;
    color: white;
}
.contact_button span {
    position: relative;
    font-weight: bold;
}
.contact_button span::before {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    content: '';
    display: inline-block;
    background-image: url('/img/top/main_button_icon1.png');
    background-size: contain;
}
.question_button{
    background-color: #f7f524;
    color: black;
}
.question_button span{
    position: relative;
    font-weight: bold;
}
.question_button span::before{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    content: '';
    display: inline-block;
    background-image: url('/img/top/main_button_icon2.png');
    background-size: contain;
}
.tel_button{
    background-color: white;
    color: #0087d1;
}
.tel_button span{
    position: relative;
    font-weight: bold;
}
.tel_button span::before{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    content: '';
    display: inline-block;
/*    background-image: url('/img/top/main_button_icon2.png');
    background-size: contain;*/
}
/* お知らせ */
.news{
    max-width: 860px;    
}
.newsbox a{
    text-decoration: underline;
    text-decoration-color: grey;
}
.news-btn{
    font-weight: bold;
    padding: 5px 8px;
    font-size: 10px;
}

/* KITATOとは？*/
.top_row{
    position: relative;
}
/* 詳しくはこちらボタン */
.detail_button{
    border: 1px solid #aaa;
    padding: 5px 15px 5px 15px;
    width: 145px;
    font-weight: bold;
    background-color: white;
    color: black;
    font-size: 12px;
}
.detail_button span{
    position: relative;
    padding-left: 2rem;
}
.detail_button span::before{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    content: '';
    display: inline-block;
    background-image: url('/img/button_arrow.png');
    background-size: contain;
    height: 16px;
    width: 16px;
}
/* HTとは？ */
.second_row{
    position: relative;
}

/* ～574px */
@media screen and (max-width: 574px) {    
    /*#message{
        top: 6vw;
        left: 7vw;
        font-size: 4.5vw;
        line-height: 1.45;
    }*/
    #catchphrase{
        top: 15vw;
        left: 25vw;
        font-size: 6vw;
        line-height: 1.45;
    }
    .key-visual::after{
        content: "";
        display: block;
        clear: both;
       }  
    .zoom_button,.zoom_button1{
        border-radius: 0.8vw;
        padding: 1vw 1vw 1vw 1vw;
        font-size: 14px;
        display: flex;
        position: absolute;
        bottom: 140px;
        right: 8vw;
    }
    .zoom_button::before,.zoom_button1::before{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -8px;
        border: 8px solid transparent;
        border-top: 8px solid white;
    }
    .contact_button  {
        border: 3px solid #eee;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .contact_button span {
        padding-left: 2.5rem;
    }
    .contact_button span::before {
        width: 24px;
        height: 24px;
    }
    .question_button{
        border: 3px solid #0087d1;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .question_button span{
        padding-left: 2.5rem;
    }
    .question_button span::before{
        width: 24px;
        height: 24px;
    }
    .tel_button  {
        border: 3px solid #0087d1;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .tel_button span {
        padding-left: 0;
    }
    .tel_button span::before {
        width: 0;
        height: 0;
    }
    .top_row {
        min-height: 380px;
    }
    .top_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        margin-left: 30px;
        margin-right: 30px;
    }
    .top_row > picture > img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        object-fit: cover;
        height:100%;
        width:100%;
    }    
    #what_kitato > picture > img{
        object-position: top left;
    }
    #what_kaihatsu > picture > img{
        object-position:top right;
    }
    .top_row > div > h2,.second_row > div > h3{
        padding-bottom: 1rem;
        font-size: 1.4rem;
    }
    .detail_button{
        margin-top: 1rem;
    }
    div.second_row{
        min-height: 340px;
    }
    .second_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        margin-left: 30px;
        margin-right: 30px;
    }
    .second_row > picture > img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        object-fit: cover;
        height:100%;
        width:100%;
    }
}
/* 768px～575px */
@media screen and (max-width: 768px) and (min-width: 575px) {  
    .top_row {
        min-height: 300px;
    }
    .top_row {
        font-size: 14px;
    }
    .top_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        margin-left: 30px;
        margin-right: 30px;
    }
    .top_row > picture > img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        object-fit: cover;
        object-position: 0 0;
        height:100%;
        width:100%;
    }
    .top_row > div > h2,.second_row > div > h3{
        padding-bottom: 1rem;
        font-size: 1.4rem;
    } 
    #what_kitato > picture > img{
        object-position: top left;
    }
    #what_kaihatsu > picture > img{
        object-position:top right;
    }
    #what_kitato > div{
        top: 0;
        margin-right: 0;
    }
    #what_kaihatsu > div{
        top: 0;
        margin-right: 0;
    }
    .detail_button{
        margin-top: 1rem;
    }
    div.second_row{
        min-height: 260px;
    }
    .second_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        margin-left: 30px;
        margin-right: 30px;
    }
    .second_row > picture > img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        left: 0;
        object-fit: cover;
        height:100%;
        width:100%;
    }
}
/* 991px～575px */
@media screen and (max-width: 991px) and (min-width: 575px) {   
    /*#message{
        top: 10vw;
        left: 7vw;
        font-size: 4.4vw;
        line-height: 1.45;
    }*/
    #catchphrase{
        top: 38%;
        left: 30%;
        font-size: 6vw;
        line-height: 1.45;
    }
    .key-visual::after{
        content: "";
        display: block;
        clear: both;
    }  
    .zoom_button,.zoom_button1{
        border-radius: 0.8vw;
        padding: 1vw 1vw 1vw 1vw;
        font-size: 1vw;
        display: flex;
        position: absolute;
        bottom: 80px;
        left: 8vw;
    }
    .zoom_button::before,.zoom_button1::before{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border: 10px solid transparent;
        border-top: 10px solid white;
    }
    .contact_button  {
        width: 50%;
        float: left;
        border: 3px solid #eee;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .contact_button span {
        padding-left: 2.5rem;
    }
    .contact_button span::before {
        width: 25px;
        height: 25px;
    }
    .question_button{
        width: 50%;
        float: left;
        border: 3px solid #0087d1;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .question_button span{
        padding-left: 2.5rem;
    }
    .question_button span::before{
        width: 24px;
        height: 24px;
    }
    .tel_button  {
        width: 50%;
        float: left;
        border: 3px solid #eee;
        border-radius: 8px;
        padding: 17px 0 17px 0;
        font-size: 16px;
        text-align: center;
    }
    .tel_button span {
        padding-left: 0;
    }
    .tel_button span::before {
        width: 0;
        height: 0;
    }
    #what_kitato > div{
        /*
        left: 40vw;
        max-width: 600px;
        */
        max-width: 430px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    #what_kaihatsu > div{
        /*
        right: 43vw;
        max-width: 600px;
        */
        max-width: 430px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    div.second_row > div > h3{
        font-size: 1.4vw;
        padding-top: 4vw;
        margin-bottom: 1.8vw;
        padding-bottom: 1.8vw;
    }
    .second_row > picture > img{
        max-width: 100%;
        /*height: auto;*/
    }
    div.second_row > div > h3{
        font-size: 1.2rem;
        padding-top: 4vw;
        margin-bottom: 1.8vw;
        padding-bottom: 1.8vw;
    }
    .second_row > picture > img{
        width: 100%;
        object-fit: cover;
    }
}
/* 992px～1499px */
@media screen and (max-width: 1499px) and (min-width: 992px){   
    /*#message{
        top: 34%;
        left: 10.5%;
        font-size: 3.5vw;
        line-height: 1.45;
    }*/
    #catchphrase{
        top: 45%;
        left: 31%;
        font-size: 6vw;
        line-height: 1.45;
    }
    .zoom_button{
        border-radius: 0.8vw;
        padding: 1vw 1vw 1vw 1vw;
        font-size: 1vw;
        display: flex;
        position: absolute;
        bottom: 8vw;
        left: 6vw;
    }
    .zoom_button::before{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border: 10px solid transparent;
        border-top: 10px solid white;
    }
    .contact_button{
        border: 0.25vw solid white;
        border-radius: 0.5vw;
        padding: 1vw 4.3vw 1vw 3vw;
        color: white;
        font-size: 1vw;
        display: flex;
        position: absolute;
        bottom: 3vw;
        left: 6vw;
    }
    .contact_button span::before{
        width: 1.4vw;
        height: 1.4vw;
    }
    .contact_button span,.question_button span{
        padding-left: 2.5vw;
    }
    .question_button span::before{
        width: 1.13vw;
        height: 1.13vw;
    }
    .question_button{
        border: 0.25vw solid #0087d1;
        border-radius: 0.5vw;
        padding: 1vw 4.3vw 1vw 3vw;
        color: black;
        font-size: 1vw;
        display: flex;
        position: absolute;
        bottom: 3vw;
        left: 23.5vw;
    }
    .tel_button{
        border: 0.25vw solid #0087d1;
        border-radius: 0.5vw;
        padding: 0.6vw 3vw 0.6vw 3vw;
        color: #0087d1;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 3vw;
        left: 40.5vw;
    }
    .tel_button span::before{
        width: 0;
        height: 0;
    }
    div.top_row {
        font-size: 14px;
    }
    .top_row > div{
        line-height: 2.1vw;
    }
    #what_kitato > div{
        left: 47vw;
        max-width: 460px;
    }
    #what_kaihatsu > div{
        right: 47vw;
        max-width: 460px;
    }
    div.second_row {
        font-size: 13px;
    }
    div.second_row > div > h3{
        font-size: 1.4vw;
        padding-top: 1.8vw;
        margin-bottom: 1.8vw;
        padding-bottom: 1.8vw;
    }
    .second_row > picture > img{
        max-width: 100%;
        height: auto;
    }
}
/* ～991px */
@media screen and (max-width: 991px){   
    .top_row > div{
        line-height: 1.3rem;
    }
    #what_ht {
        background: linear-gradient(90deg,#fae3f7, #f7dff5);
        width: 100%;
    }
    #what_dev {
        background: linear-gradient(90deg,#d0dff2, #d8e9fb);
        width: 100%;
    }
}

/* 768px～991px */
@media screen and (max-width: 991px) and (min-width: 768px){  
    .top_row {
        font-size: 13px;
    }
}
/* 768px～1499px */
@media screen and (max-width: 1499px) and (min-width: 769px){  
    #what_kitato > div{
        top: 15vw;
        margin-right: 3vw;
    }
    #what_kaihatsu > div{
        top: 15vw;
        margin-left: 3vw;
    }
}
/* 575px～1499px */
@media screen and (max-width: 1499px) and (min-width: 575px){   
    .top_row > div{
        position: absolute;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
    }
    div.top_row > div > h2{
        font-weight: 600;
        margin-top: 2vw;
        margin-bottom: 2vw;
        font-size: 2.1vw;
    }
    .second_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.5rem;
        max-width: 500px;
    }
    div.second_row > div > h3{
        font-weight: 600;
        text-align: center;
        position: relative;
    }
    div.second_row > div > h3::after{
        position: absolute;
        content: "";
        display: block;
        width: 2.7vw;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    div.second_row > .detail{
        min-height: 100px;
    }
    div.second_row > div > div.detail_button{
        margin-top: 10px;
        margin-bottom: 10px;
        height: auto;
    }
    #what_ht h3::after{
        background-color: #f07ecf;
    }
    #what_dev h3::after{
        background-color: #3290fc;
    }
}
/* 1500px～ */
@media screen and (min-width: 1500px){
    /*#message{
        top: 34%;
        left: 10.5%;
        font-size: 3.5rem;
        line-height: 1.45;
    }*/
    #catchphrase{
        top: 45%;
        left: 30%;
        font-size: 6rem;
        line-height: 1.45;
    }
    .zoom_button{
        border: none;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;
        font-size: 14px;
        display: flex;
        position: absolute;
        bottom: 115px;
        left: 100px;
    }
    .contact_button{
        border: 3px solid white;
        border-radius: 8px;
        padding: 17px 60px 17px 60px;
        font-size: 16px;
        display: flex;
        position: absolute;
        bottom: 35px;
        left: 100px;
    }
    .contact_button span{
        padding-left: 2.5rem;
    }
    .contact_button span::before{
        width: 25px;
        height: 25px;
    }
    .question_button{
        border: 3px solid #0087d1;
        border-radius: 8px;
        padding: 17px 60px 17px 60px;
        font-size: 16px;
        display: flex;
        position: absolute;
        bottom: 35px;
        left: 380px;
    }
    .question_button span{
        padding-left: 2.5rem;
    }
    .question_button span::before{
        width: 24px;
        height: 24px;
    }
    .tel_button{
        border: 3px solid #0087d1;
        border-radius: 8px;
        padding: 17px 60px 17px 60px;
        font-size: 16px;
        display: flex;
        position: absolute;
        bottom: 35px;
        left: 660px;
    }
    .tel_button span{
        padding-left: 0;
    }
    .tel_button span::before{
        width: 0;
        height: 0;
    }
    .top_row > div{
        position: absolute;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.8rem;
        margin: auto;
    }
    .top_row > div > h2{
        font-weight: 600;
        font-size: 2.125rem;
        margin-top: 0;
        margin-bottom: 40px;
    }
    .top_row > div > div{
        font-size: 16px;
    }
    .detail_button {
        margin-top: 1rem;
    }
    #what_kitato > div{
        top: 51%;
        left: 50%;
        max-width: 600px;
    }
    #what_kaihatsu > div{
        right: 50%;
        top: 51%;
        max-width: 600px;
    }
    #what_kaihatsu > div > div.detail_button {
        margin-top: 4.5rem;
    }
    .second_row > div{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        line-height: 1.5rem;
        max-width: 500px;
    }
    div.second_row > div > h3{
        font-weight: 600;
        text-align: center;
        position: relative;
        font-size: 1.35rem;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    div.second_row > div > h3::after{
        position: absolute;
        content: "";
        display: block;
        width: 12%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    div.second_row > div > div{
        font-size: 14px;
    }
    .detail{
        text-align: left;
        line-height: 1.9rem;
        font-size: 14px;
    }
    #what_ht > div{
        left: 20%;
    }
    #what_ht h3::after{
        background-color: #f07ecf;
    }
    #what_dev > div{
        left: 20%;
    }
    #what_dev h3::after{
        background-color: #3290fc;
    }
    .footer > div.w-830{
        height: 235px;
    }
}
